import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/index"
import SEO from "../components/seo"
import OrganizationlChart from "../components/organizationalChart/index"
import { StyledContainer } from "../components/containers/index"
import StyledSectionBox from "../components/sectionBox/index"

const GroupStructurePageZhCn = ({ location, data: { background } }) => {
  return (
    <Layout location={location}>
      <SEO
        title="Frameway Industries Ltd. - Transfer Parts Manufacturer, Progressive Parts Manufacturer, Multi-slide Bending Parts Manufacturer"
        lang="zh-cn"
      />
      <StyledSectionBox
        background={background.childImageSharp.fluid}
        title="groupStructure"
      />
      <StyledContainer>
        <OrganizationlChart />
      </StyledContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    background: file(relativePath: { eq: "about/about-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default GroupStructurePageZhCn
